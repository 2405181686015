/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import "assets/css/Conversation.css";
import React, { forwardRef, memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import cockpitActions from "../../redux/actions/cockpitActions";
import { useFAibrikContext } from "../../context/Context";
import ChannelUserInfoMembershipDisplayer from "./../../helpers/ChannelUserInfoMembershipDisplayer";
import useCopyToClipboard from "../../helpers/useCopyToClipboard";
import PriorityIntentSentimentDisplayer from "./PriorityIntentSentimentDisplayer";
import {
	Box,
	Grid,
	IconButton,
	Tooltip,
	Badge,
	Popover,
	Chip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Switch,
	FormControlLabel
} from "@material-ui/core";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";

import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import SendIcon from "@material-ui/icons/Send";
import EmailIcon from "@material-ui/icons/Email";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import SettingsIcon from "@material-ui/icons/Settings";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import EventIcon from "@material-ui/icons/Event";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import i18n from "i18n";
import { getHeaderConvItemSelector } from "redux/selectors/cockpitSelectors";
import { useSelector } from "react-redux";
import {
	AssignConversation,
	RedirectConversation,
	RejectConversation,
	UpdateConversationState,
	ActionConversation,
	ReopenHiddenConversation,
	DeleteDraft
} from "./ConvActions/ConvItemComponents";
import { displayCorrectName } from "helpers/utilities";
import { TagsList } from "components/Tags/TagsList";
import { getConversationGroups } from "helpers/utilities";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import RedirectionDialogGroups from "./ConvActions/RedirectionDialogGroups";
import RedirectionDialogUsers from "./ConvActions/RedirectionDialogUsers";
import { Duration } from "luxon";
import lod_ from "lodash";
import { api } from "redux/actions/api";
import { handleSpecialDisplayDate } from "helpers/utilities";
import HeaderConvItemRecording from "./HeaderConvItemComponents/HeaderConvItemRecording";
import { getLabelOf } from "helpers/utilities";
import { ForumOutlined } from "@material-ui/icons";

/**
 *
 */
const HeaderConvItem = forwardRef(
	(
		{
			handleDisplayForwardedMessages,
			setDisplayHistory,
			convHistory,
			displayHistory,
			setSnackbarStatus,
			handleOpenRightMenu,
			setConvHistoryFromBack,
			displaySummary,
			handleChangeDisplaySummary
		},
		ref
	) => {
		const dispatch = useDispatch();
		let { selectedConversation, userStatus, historyLoading, countHistory, assistantconfig } =
			useSelector(getHeaderConvItemSelector, shallowEqual);
		let isHistoryConvOngoingOrWaiting = useSelector(
			state => state.cockpit.isHistoryConvOngoingOrWaiting
		);
		const { fAibrikStatus } = useFAibrikContext();
		const isHumanRequest = selectedConversation.header.humanRequest;
		const isBot = selectedConversation.agent.isBot;
		const contactNameOrEmail = selectedConversation.meta?.cockpit?.contact
			? selectedConversation.meta.cockpit.contact
			: "";
		const hasEditRights =
			userStatus.auth.user.email === selectedConversation.agent.uid ||
			userStatus.auth.user.role === "supervisor" ||
			userStatus.auth.user.role === "soloUser";
		const isCall =
			selectedConversation?.header?.type === "call" &&
			!selectedConversation?.messages?.some(message =>
				["transcript", "recording"].includes(message?.payload?.header?.context?.status)
			);
		let conversationGroups = getConversationGroups();
		const StyledBadge = withStyles(theme => ({
			badge: {
				top: 0,
				border: `2px solid ${theme.palette.background.paper}`,
				padding: "0 4px",
				backgroundColor: isHistoryConvOngoingOrWaiting ? "#545454" : "green"
			}
		}))(Badge);

		/**
		 * Check if the conversation has forwarded messages
		 * used to display the icon
		 */
		const hasForwardedMessages = (selectedConversation?.messages ?? []).some(
			message => message?.header?.type === "forwarded" || message?.header?.type === "note"
		);

		const [openGroupRedirectionDialog, setOpenGroupRedirectionDialog] = useState(false);

		const handleOpenGroupRedirectionDialog = () => {
			if (CONDITIONS_REDIRECT_GROUP && fAibrikStatus.has("write_assignconv") && hasEditRights) {
				setOpenGroupRedirectionDialog(true);
			}
		};
		const handleCloseGroupRedirectionDialog = () => {
			setOpenGroupRedirectionDialog(false);
		};

		const [openUsersRedirectionDialog, setOpenUsersRedirectionDialog] = useState(false);

		const handleOpenUsersRedirectionDialog = () => {
			if (CONDITIONS_REDIRECT_USER) {
				setOpenUsersRedirectionDialog(true);
			}
		};
		const handleCloseUsersRedirectionDialog = () => {
			setOpenUsersRedirectionDialog(false);
		};
		/**
		 * Get history count for selectedConversation && handle display history
		 */
		useEffect(() => {
			if (selectedConversation) {
				setDisplayHistory(false);
			}
		}, [selectedConversation]);

		/**
		 * Reset historyLoaded state and empty history conv when switching selected conversation
		 */
		useEffect(() => {
			dispatch(cockpitActions.EmptyHistory());
		}, [selectedConversation?.header?.fID]);

		/**
		 *
		 */
		useEffect(() => {
			if (historyLoading) {
				handleShowHistory();
			}
		}, [historyLoading]);

		/**
		 * stop timer if call is disconnected
		 *
		 */
		const [timerActive, setTimerActive] = useState(true);

		useEffect(() => {
			// Vérifiez si l'événement Disconnected existe
			const isDisconnected = selectedConversation.events?.some(
				event => event.name === "Disconnected"
			);

			if (isDisconnected) {
				// Si l'événement Disconnected est présent, arrêtez le timer
				setTimerActive(false);
			}
		}, [selectedConversation.events]);

		/**
		 *
		 * @returns
		 */
		const handleFetchHistory = () => {
			if (historyLoading) {
				return handleShowHistory();
			}
			if (!displayHistory) {
				dispatch(cockpitActions.FetchHistory(selectedConversation));
			} else {
				handleShowHistory();
			}
		};

		/**
		 *
		 */
		const handleShowHistory = () => {
			setDisplayHistory(!displayHistory);
			!isCall &&
				(displayHistory === true
					? setSnackbarStatus({ open: true, message: i18n.t("COC.HideHistory") })
					: setSnackbarStatus({ open: true, message: i18n.t("COC.ShowHistory") }));
		};

		/**
		 *
		 * @param {*} props
		 * @returns
		 */
		const SubStateText = props => {
			let substate = displayCorrectName(props.substate);
			if (substate === null) {
				return null;
			} else {
				return " / " + substate;
			}
		};

		/**
		 *
		 * @returns
		 */
		const getReviewIcon = () => {
			if (selectedConversation?.context?.review?.type) {
				return (
					<div className="av-source">
						<div>{selectedConversation.context?.review?.type[0].toUpperCase()}</div>
					</div>
				);
			} else {
				return <></>;
			}
		};

		/**
		 * - Not a draft
		 * - Conv is ongoing or resolved
		 * - Has edit rights
		 */
		const CONDITIONS_REJECT_CONV =
			(selectedConversation.header.substate !== "draft" &&
				(selectedConversation.header.state === "ongoing" ||
					selectedConversation.header.state === "resolved") &&
				hasEditRights) ||
			(selectedConversation.header.type === "call" &&
				selectedConversation.header.substate === "draft");

		/**
		 * - Not a draft
		 * - Conv is waiting
		 */
		const CONDITIONS_ASSIGN_CONV =
			selectedConversation.header.substate !== "draft" &&
			selectedConversation.header.state === "waiting";

		/**
		 * - Not a draft
		 * - Not archived
		 * - Not hidden
		 * - Not a call
		 */
		const CONDITIONS_REDIRECT_GROUP =
			selectedConversation.header.substate !== "draft" &&
			selectedConversation.header.state !== "archived" &&
			selectedConversation.header.state !== "hidden" &&
			!isCall;

		// Conditions for redirecting to user
		//        There is no sense to redirect to user for some types
		const CONDITIONS_REDIRECT_USER___CONV_CORRECT_STATE =
			selectedConversation.header.substate !== "draft" &&
			selectedConversation.header.state !== "archived" &&
			selectedConversation.header.state !== "hidden" &&
			!isCall;

		//        supervisor and soloUser can redirect to user
		const CONDITIONS_REDIRECT_USER___USER_PERMISSIONS___SUPERVISOR_SOLOUSER = [
			"supervisor",
			"soloUser"
		].includes(userStatus.auth.user.role);

		//        agent can assign/deassign conversation
		const CONDITIONS_ASSIGNATION_USER_USER_PERMISSIONS_AGENT =
			assistantconfig?.cockpit?.agentCanAssignationConv;

		//        agent can redirect to user only if assistantconfig.cockpit.agentCanRedirectToUser
		//		  is true and if the conversation is waiting or if the agent is the owner of the conversation
		const CONDITIONS_REDIRECT_USER___USER_PERMISSIONS___AGENT =
			["agent"].includes(userStatus.auth.user.role) &&
			assistantconfig?.cockpit?.agentCanRedirectToUser &&
			(selectedConversation.agent.uid === userStatus.auth.user.email ||
				selectedConversation.header.state === "waiting" ||
				assistantconfig?.cockpit?.agentCanAssignationConv);

		//		  user can redirect to user if he has the permission and the correct role permission
		const CONDITIONS_REDIRECT_USER___USER_PERMISSIONS =
			fAibrikStatus.has("write_assignconv") &&
			(CONDITIONS_REDIRECT_USER___USER_PERMISSIONS___SUPERVISOR_SOLOUSER ||
				CONDITIONS_REDIRECT_USER___USER_PERMISSIONS___AGENT);

		//        all conditions for redirecting to user
		const CONDITIONS_REDIRECT_USER =
			CONDITIONS_REDIRECT_USER___CONV_CORRECT_STATE && CONDITIONS_REDIRECT_USER___USER_PERMISSIONS;

		/**
		 * - Conv is hidden
		 */
		const CONDITIONS_REOPEN_CONV = selectedConversation.header.state === "hidden";

		/**
		 * - Conv is draft
		 * - Conv is ongoing
		 */
		const CONDITIONS_DELETE_DRAFT =
			selectedConversation.header.substate == "draft" &&
			selectedConversation.header.state === "ongoing";

		/**
		 * - Conv is not hidden
		 */
		const CONDITIONS_RIGHT_TAB_MENU = selectedConversation.header.state !== "hidden";

		/**
		 * Conv is archived
		 */
		const CONDITION_ARCHIVED_CONV = selectedConversation.header.state === "archived";

		/**
		 * - Menu Action
		 */
		const [openAction, setOpenAction] = useState(false);
		const [anchorAction, setAnchorAction] = useState(null);
		const handleClose = () => {
			setOpenAction(false);
			setAnchorAction(null);
		};

		const openActionMenu = event => {
			setAnchorAction(event.currentTarget);
			setOpenAction(true);
		};

		const getPathDicoToShow = path => {
			let value = lod_.get(selectedConversation, path);

			if (lod_.isString(value) && !lod_.isNil(value) && !lod_.isEmpty(value)) {
				return <span className="showDicoType">{value}</span>;
			}
		};

		return (
			<>
				<Box bgcolor="white" m={1} data-component-name="HeaderConvItem">
					<Grid container direction="row">
						<Grid
							item
							container
							direction="column"
							xs={4}
							data-component-name="HeaderConvItem-col-1"
						>
							<Grid
								item
								style={{ flex: 1 }}
								/* xs={4} */ container
								// justifyContent="center"
								direction="row"
								alignItems="center"
							>
								{getReviewIcon()}
								<ChannelUserInfoMembershipDisplayer
									convHistory={convHistory}
									channelImage={selectedConversation.meta?.cockpit?.channelCode || ""}
									connected={selectedConversation.meta?.cockpit?.connected || false}
									channel={selectedConversation.header.channel}
									contactNameOrEmail={contactNameOrEmail}
									FRU={selectedConversation.contact?.FRU}
									assistantID={selectedConversation.header.assistantID}
									fID={selectedConversation.header.fID}
									displayDialog={true}
								/>
							</Grid>
							{/* Grid below is used for creating space for engage to display error message */}
							<Grid
								container
								style={selectedConversation?.header?.substate === "draft" ? { height: "34px" } : {}}
							></Grid>
							{
								<Box display="flex" flexDirection="row" alignItems="center">
									{selectedConversation.header.fID ? (
										<Fid fID={selectedConversation.header.fID} />
									) : (
										""
									)}
									{/* Reviews, display type of review */}
									{selectedConversation.header.type === "review" &&
										selectedConversation.header.subtype && (
											<span
												className={
													selectedConversation?.meta?.review?.report === true
														? "trustpilotReviewTypeReported"
														: "trustpilotReviewType"
												}
											>
												{i18n.t(
													`COC.headerConv.review.type.${selectedConversation.header.subtype}`
												) || "Review"}
											</span>
										)}
									{!lod_.isNil(assistantconfig?.cockpit?.showDictionaryPath) &&
										!lod_.isEmpty(assistantconfig?.cockpit?.showDictionaryPath) &&
										getPathDicoToShow(assistantconfig.cockpit.showDictionaryPath)}
									{countHistory > 0 && !isCall && (
										<Box>
											<Tooltip title={i18n.t("COC.History")} placement="right-start">
												<IconButton size="small" onClick={handleFetchHistory}>
													<StyledBadge badgeContent={countHistory} color="primary" showZero={false}>
														<HistoryOutlinedIcon
															fontSize="small"
															style={{
																color: isHistoryConvOngoingOrWaiting ? "-moz-initial" : "green"
															}}
														/>
													</StyledBadge>
												</IconButton>
											</Tooltip>
										</Box>
									)}
									<Box
										style={{
											paddingLeft: "2%",
											display: "flex",
											alignItems: "center",
											flexDirection: "row"
										}}
									>
										<HeaderConvItemRecording />
										{selectedConversation?.meta?.cockpit?.summary &&
											assistantconfig?.cockpit?.displaySummary && (
												<Tooltip
													title={
														!displaySummary ? i18n.t("COC.summary") : i18n.t("COC.summaryHidden")
													}
													placement="right-start"
												>
													<IconButton size="small" onClick={handleChangeDisplaySummary}>
														<LibraryBooksIcon fontSize="small" />
													</IconButton>
												</Tooltip>
											)}
										{assistantconfig?.cockpit?.displayConvEvents && !isCall && (
											<DisplayModalConvEvents />
										)}
										{hasForwardedMessages && (
											<Box>
												<Tooltip title={i18n.t("COC.forwardedMessages")} placement="right-start">
													<IconButton size="small" onClick={handleDisplayForwardedMessages}>
														<ForumOutlined fontSize="small" />
													</IconButton>
												</Tooltip>
											</Box>
										)}
									</Box>
								</Box>
							}
						</Grid>
						<Grid
							container
							direction="column"
							style={{ flex: 1, width: "100%" }}
							data-component-name="HeaderConvItem-col-2"
						>
							<Grid
								item
								/* xs={4} */ container
								justifyContent="center"
								direction="row"
								alignItems="center"
							>
								<PriorityIntentSentimentDisplayer
									priority={selectedConversation.meta?.priority}
									// Always display topic first if it exists
									title={
										selectedConversation.meta?.topic ?? selectedConversation.meta?.cockpit?.title
									}
									intent={selectedConversation.meta?.intentCode}
									sentiment={selectedConversation.meta?.sentiment}
									assistantID={selectedConversation.header.assistantID}
									fID={selectedConversation.header.fID}
									setSnackbarStatus={setSnackbarStatus}
									parentName={"HeaderConvItem"}
								/>
							</Grid>
							<Grid
								item
								container
								justifyContent="center"
								direction="row"
								alignItems="center"
								onClick={handleOpenGroupRedirectionDialog}
							>
								<TagsList
									list={conversationGroups}
									labelField={"name"}
									titleField={"description"}
									style={{ width: "100%", marginRight: "0%" }}
								/>
							</Grid>
							{openGroupRedirectionDialog && (
								<RedirectionDialogGroups
									handleCloseGroupRedirectionDialog={handleCloseGroupRedirectionDialog}
									open={openGroupRedirectionDialog}
									showRedirectionSuccessPopup={() =>
										dispatch(infoMsg(i18n.t("COC.redirectedConv")))
									}
								/>
							)}
						</Grid>
						<Grid
							item
							container
							direction="column"
							xs={3}
							justifyContent="flex-end"
							data-component-name="HeaderConvItem-col-3"
						>
							<Grid item container justifyContent="flex-end" direction="row" alignItems="center">
								{selectedConversation.meta.callDuration &&
									`${i18n.t("COC.callTime")}: 
										${Duration.fromMillis(selectedConversation.meta.callDuration).toFormat("mm:ss")}`}

								{/* Reject conversation / Update conversation state */}
								{CONDITIONS_REJECT_CONV && (
									<React.Fragment>
										{!isHumanRequest && !isBot && !isCall && (
											<RejectConversation
												setDisplayHistory={setDisplayHistory}
												setConvHistoryFromBack={setConvHistoryFromBack}
											/>
										)}
										<UpdateConversationState />
										<ActionConversation />
									</React.Fragment>
								)}
								{/* Assign conversation / Update conversation state. Should mutually exclude CONDITIONS_DELETE_DRAFT*/}
								{CONDITIONS_ASSIGN_CONV && (
									<React.Fragment>
										{!isCall && <AssignConversation />}
										{!isCall && <ActionConversation />}
										{assistantconfig?.cockpit?.conv_display_quick_action_menu && (
											<UpdateConversationState />
										)}
									</React.Fragment>
								)}
								{CONDITION_ARCHIVED_CONV && (
									<React.Fragment>
										<ActionConversation />
									</React.Fragment>
								)}

								{/* Reopen hidden conversation from hidden tab */}
								{CONDITIONS_REOPEN_CONV && <ReopenHiddenConversation />}
								{/* Delete draft. Should mutually exclude CONDITIONS_ASSIGN_CONV */}
								{CONDITIONS_DELETE_DRAFT && (
									<React.Fragment>
										<DeleteDraft />
										{assistantconfig?.cockpit?.conv_display_quick_action_menu && (
											<UpdateConversationState />
										)}
										{!isCall && <ActionConversation />}
									</React.Fragment>
								)}
								{/* Open right tab menu info */}
								{/* HERE */}
								{CONDITIONS_RIGHT_TAB_MENU && (
									<Box component="span" mx={0.5}>
										<Tooltip
											title={i18n.t("COC.MenuOpenClose")}
											placement="top"
											onClick={handleOpenRightMenu}
										>
											<IconButton size="small">
												{" "}
												<MoreVertOutlinedIcon />{" "}
											</IconButton>
										</Tooltip>
									</Box>
								)}
							</Grid>
							<Grid item container justifyContent="center" direction="row" alignItems="center">
								<Box flexGrow={1} display="flex" justifyContent="flex-end">
									{selectedConversation.header.state === "waiting" && (
										<Box display="flex" borderRadius="borderRadius" borderColor="#9e9e9e">
											<Box fontWeight="fontWeightMedium" mr={1}>
												{i18n.t("COC.Pending")}
											</Box>
											<HourglassEmptyIcon fontSize="small" />
											<Box
												ml={1}
												style={{
													textTransform: "capitalize",
													fontSize: "16px",
													cursor: CONDITIONS_REDIRECT_USER ? "pointer" : "unset"
												}}
												onClick={handleOpenUsersRedirectionDialog}
											>
												{`(${i18n.t("COC.unassigned")})`}
											</Box>
										</Box>
									)}
									{selectedConversation.header.state === "ongoing" && (
										<Box
											display="flex"
											flexWrap="wrap"
											justifyContent="flex-end"
											alignItems="center"
											borderRadius="borderRadius"
											borderColor="#9e9e9e"
										>
											<Box fontWeight="fontWeightMedium" fontFamily="Roboto" fontSize="16px">
												{selectedConversation.header.substate !== "draft"
													? i18n.t("COC.Inprogress")
													: i18n.t("COC.InDraft")}
												{
													<SubStateText
														t={i18n.t}
														substate={selectedConversation.header.substate}
													/>
												}
											</Box>
											{selectedConversation.agent.name || selectedConversation.agent.uid ? (
												<Box
													ml={1}
													style={{
														fontSize: "16px",
														cursor: CONDITIONS_REDIRECT_USER ? "pointer" : "unset"
													}}
													onClick={handleOpenUsersRedirectionDialog}
												>
													{`(${selectedConversation.agent.name || selectedConversation.agent.uid})`}
												</Box>
											) : (
												<></>
											)}
										</Box>
									)}
									{openUsersRedirectionDialog && (
										<RedirectionDialogUsers
											handleCloseUsersRedirectionDialog={handleCloseUsersRedirectionDialog}
											open={openUsersRedirectionDialog}
											showRedirectionSuccessPopup={() =>
												dispatch(infoMsg(i18n.t("COC.redirectedConv")))
											}
										/>
									)}
									{selectedConversation.header.state === "resolved" && (
										<Box
											display="flex"
											flexWrap="wrap"
											justifyContent="flex-end"
											alignItems="center"
											borderRadius="borderRadius"
											borderColor="#9e9e9e"
										>
											<Box fontWeight="fontWeightMedium">
												{i18n.t("COC.resolved")}
												{
													<SubStateText
														t={i18n.t}
														substate={selectedConversation.header.substate}
													/>
												}
											</Box>
											{selectedConversation.agent.name || selectedConversation.agent.uid ? (
												<Box ml={1} style={{ textTransform: "capitalize" }}>
													{`(${selectedConversation.agent.name || selectedConversation.agent.uid})`}
												</Box>
											) : (
												<></>
											)}
										</Box>
									)}
									{selectedConversation.header.state === "archived" && (
										<Box
											display="flex"
											flexWrap="wrap"
											justifyContent="flex-end"
											alignItems="center"
											borderRadius="borderRadius"
											borderColor="#9e9e9e"
										>
											<Box fontWeight="fontWeightMedium">{i18n.t("COC.archived")}</Box>
											{selectedConversation.agent.name || selectedConversation.agent.uid ? (
												<Box ml={1} style={{ textTransform: "capitalize" }}>
													{`(${selectedConversation.agent.name || selectedConversation.agent.uid})`}
												</Box>
											) : (
												<></>
											)}
										</Box>
									)}
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</>
		);
	}
);

const MemoizedHeaderConvItem = memo(HeaderConvItem);
export default MemoizedHeaderConvItem;

export const DisplayModalConvEvents = () => {
	const [activeStep, setActiveStep] = useState(null);
	const [showConvHistory, setShowConvHistory] = useState(false);
	const [convEvents, setConvEvents] = useState([]);
	const [showFilterEvent, setShowFilterEvent] = useState(true);
	const { groups } = useSelector(state => state.userGroup);
	let { selectedConversation, userStatus, assistantconfig } = useSelector(
		getHeaderConvItemSelector,
		shallowEqual
	);
	const dispatch = useDispatch();

	/**
	 *
	 * @returns list of convEvents
	 */
	const handleShowConvHistory = () => {
		setShowConvHistory(true);
		let fID = selectedConversation.header.fID;
		let assistantID = selectedConversation.header.assistantID;
		dispatch(
			api({
				method: "POST",
				type: "cockpit/convEvents",
				url: `/api/v1/convEvents`,
				data: { fID, assistantID },
				onSuccess: res => {
					setConvEvents(res);
				}
			})
		);
	};

	let isHistoryConvOngoingOrWaiting = useSelector(
		state => state.cockpit.isHistoryConvOngoingOrWaiting
	);
	const StyledBadge = withStyles(theme => ({
		badge: {
			top: 0,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: "0 4px",
			backgroundColor: isHistoryConvOngoingOrWaiting ? "#545454" : "green"
		}
	}))(Badge);

	/**
	 * Set true filter for no show view conv event by default
	 */
	useEffect(() => {
		if (selectedConversation) {
			setShowFilterEvent(true);
		}
	}, [showConvHistory]);

	const handleCloseEvents = () => {
		setShowConvHistory(false);
	};

	const handleStepClick = stepIndex => {
		activeStep === stepIndex ? setActiveStep(null) : setActiveStep(stepIndex);
	};

	const eventText = event => {
		return (
			i18n.t(`actionEvent.${event?.header?.state}`) +
			" " +
			(event?.header?.ts
				? i18n.t("actionEvent.the") +
					" " +
					handleSpecialDisplayDate(
						event?.header?.ts,
						assistantconfig?.cockpit?.datetime_format?.format,
						userStatus.auth.user.language.toLowerCase()
					)
				: " ") +
			" " +
			(event?.agent?.agentSender === "none"
				? ""
				: i18n.t("actionEvent.by") +
					" " +
					(event?.agent?.agentSender
						? event?.agent?.agentSender !== "system"
							? event?.agent?.agentSender
							: i18n.t("actionEvent.anSystem")
						: i18n.t("actionEvent.anAgent")))
		);
	};

	const eventDesc = event => {
		switch (event?.header?.state) {
			case "ASSIGN":
				if (event?.agent?.agentID === event?.payload.eventData?.to?.email) {
					return `${event?.agent?.agentSender} ${i18n.t(`actionEvent.ASSIGN_SOLO`)}`;
				} else {
					return `${event?.agent?.agentSender} ${i18n.t(`actionEvent.ASSIGN_TO`)} ${
						event?.payload.eventData?.to?.name
							? event?.payload.eventData?.to?.name
							: event?.payload.eventData?.to?.email
					}`;
				}
			case "UPDATE_STATE":
				return `${i18n.t(`actionEvent.UPDATE_STATE_DESC`)} ${i18n.t(
					`HISTORY.${event?.payload.eventData?.state}`
				)} / ${i18n.t(`convItemRender.${event?.payload.eventData?.substate}`)}`;
			case "UPDATE_CONTEXT":
			case "UPDATE_CONTACT":
				if (!lod_.isNil(event?.payload?.comment) && event?.payload?.comment !== "") {
					return `${i18n.t(`actionEvent.commentContextContact`)}` + event?.payload?.comment || "";
				} else {
					return "Aucune description";
				}

			case "UPDATE_GROUPS": {
				let comment = "";
				if (
					!lod_.isEmpty(groups) &&
					!lod_.isEmpty(event?.payload?.eventData?.resolverGroup) &&
					!lod_.isNil(event?.payload?.eventData?.resolverGroup)
				)
					event.payload.eventData.resolverGroup.forEach(testId => {
						const item = groups.find(entry => entry._id === testId);
						if (!lod_.isNil(item)) {
							comment += comment === "" ? item.name : ", " + item.name;
						}
					});
				return `${i18n.t(`actionEvent.skills`)} ${comment}`;
			}
			case "UPDATE_KEY_DICTIONARY":
				return `${i18n.t(`actionEvent.commentContextContact`)}` + event?.payload?.comment || "";

			case "UNSET_KEY_DICTIONARY":
				return `${i18n.t(`actionEvent.commentUnset`)}` + event?.payload?.comment || "";

			case "CHANGE_STATUS_CATEGORY":
			case "DELETE_CATEGORY":
			case "ADD_CATEGORY":
				return (
					`${i18n.t(`actionEvent.commentCategory`)}` +
						getLabelOf(event?.payload?.eventData?.category?.code) || ""
				);
			case "UPDATE_FEEDBACK":
				return (
					`${i18n.t(`actionEvent.commentFeedback`)}` +
						getLabelOf(
							event?.payload?.eventData?.proposedIntent || event?.payload?.eventData?.selectedIntent
						) || ""
				);
			case "SET_ESCALATE":
				return (
					`${i18n.t(`actionEvent.skillLabel`)}` +
						getLabelOf(event?.payload?.eventData?.group?.name) || ""
				);
			default:
				return "Aucune description";
		}
	};

	function ColorlibStepIcon(props, state) {
		switch (state) {
			case "UPDATE_CONTACT":
				return <PermContactCalendarIcon />;
			case "MESSAGE_OUT":
				return <SendIcon />;
			case "MESSAGE_IN":
				return <EmailIcon />;
			case "REJECT":
				return <SpeakerNotesOffIcon />;
			case "ASSIGN":
				return <AssignmentReturnedIcon />;
			case "UPDATE_STATE":
				return <SettingsIcon />;
			case "UPDATE_CONTEXT":
				return <LibraryBooksIcon />;
			case "SET_ESCALATE":
				return <TrendingUpIcon />;
			case "ESCALATE_DONE":
				return <TrendingUpIcon style={{ color: "green" }} />;
			default:
				return <SettingsIcon />;
		}
	}

	return (
		<>
			<Tooltip title={i18n.t("COC.convEventsHistory")} placement="right-start">
				<IconButton size="small" onClick={handleShowConvHistory} ml={1}>
					<StyledBadge color="primary" showZero={false}>
						<EventIcon fontSize="small" />
					</StyledBadge>
				</IconButton>
			</Tooltip>
			<Dialog
				fullWidth
				maxWidth="md"
				open={showConvHistory}
				onClose={handleCloseEvents}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					<Box style={{ display: "flex", justifyContent: "space-between" }}>
						<Box>{i18n.t(`actionEvent.listEvents`)}</Box>
						<Box>
							<FormControlLabel
								control={
									<Switch
										checked={showFilterEvent}
										onChange={e => setShowFilterEvent(e.target.checked)}
									/>
								}
								labelPlacement="start"
								label={i18n.t(`actionEvent.showFilterEvent`)}
							/>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent
					style={{ height: "65vh", display: "flex", flexDirection: "column" }}
					dividers
				>
					{!lod_.isEmpty(convEvents) ? (
						<Stepper orientation="vertical" activeStep={activeStep}>
							{convEvents
								.filter(event => {
									if (showFilterEvent === true) {
										return event?.header?.state !== "VIEW_CONVERSATION";
									} else {
										return true;
									}
								})
								.map((event, index) => (
									<Step key={index} onClick={() => handleStepClick(index)}>
										<StepLabel
											StepIconComponent={props => ColorlibStepIcon(props, event.header.state || "")}
											style={{ cursor: "pointer" }}
											completed={false}
										>
											{eventText(event)}
										</StepLabel>
										<StepContent>{eventDesc(event)}</StepContent>
									</Step>
								))}
						</Stepper>
					) : (
						<Box
							style={{
								width: "100¨%",
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							}}
						>
							{i18n.t(`actionEvent.noEvents`)}
						</Box>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseEvents} color="primary" style={{ marginRight: 27 }}>
						{`${i18n.t("alert.confirm")}`}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

const Fid = ({ fID }) => {
	const [, handleCopy] = useCopyToClipboard(2000);
	const dispatch = useDispatch();

	/**
	 *
	 */
	const saveFID = () => {
		handleCopy(fID);
		dispatch(infoMsg(i18n.t("COC.CopiedToClipboard")));
		//	setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};
	return (
		<Tooltip title={i18n.t("COC.CopyToClipboard")}>
			<p
				style={{
					margin: "0 0 0 0",
					paddingTop: "3px",
					fontFamily: "Roboto",
					fontSize: "16px"
				}}
				className="header_fid"
				onClick={saveFID}
			>
				{fID}
			</p>
		</Tooltip>
	);
};
