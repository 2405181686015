/* eslint-disable no-fallthrough */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import C from "../../constants/cockpit.js";
import lod_ from "lodash";
import { getConversationTypeByLeftTab } from "helpers/utilities.js";
import { conversationPassTheFilters } from "helpers/utilities.js";
import { isConversationLive } from "helpers/utilities.js";
import { preloadedState } from "redux/store.js";
import { getLeftTabByConversationType } from "helpers/utilities.js";
import {
	LEFT_TAB_BY_CONVERSATION_TYPE,
	LEFT_TAB_BY_CONVERSATION_TYPE_ARCHIVED,
	PAGES_IN_COCKPIT
} from "helpers/utilities.js";

const cockpitReducers = function (currentState = {}, action) {
	const constructPivot = () => {
		const convMessage = {
			assistantID: currentState.selectedConversation?.header?.assistantID,
			fID: currentState.selectedConversation?.header?.fID,
			mID: currentState?.currentMessage?.mID,
			message: {
				header: {
					type: "content"
				},
				payload: {}
			}
		};
		return convMessage;
	};

	switch (action.type) {
		case C.UPDATE_CONV: {
			const data = action.payload;
			// Update the list of conversation
			let updateConversationList = currentState.conversations.map(conv => {
				if (conv.header.fID === data.fID) {
					return data.conv;
				}
				return conv;
			});

			// Update the selected conversation if needed
			let selectedConversation = currentState.selectedConversation;
			if (selectedConversation?.header?.fID === data.fID) {
				selectedConversation = data.conv;
			}

			return {
				...currentState,
				conversations: updateConversationList,
				selectedConversation: selectedConversation
			};
		}
		case C.SET_FORCE_SELECT_CONVERSATION: {
			return { ...currentState, forceSelectConversation: action.payload };
		}
		case C.INSERT_HTML:
			return {
				...currentState,
				htmlToInsert: action.payload
			};

		case C.CLEAR_INSERT_HTML:
			return {
				...currentState,
				htmlToInsert: null
			};

		case C.RESET_COCKPIT: {
			return preloadedState.cockpit;
		}

		case C.UPDATE_CONV_CONTACT: {
			let newContact = action.payload.data.contact;
			let conversationfID = action.payload.data.id;
			let computedContact =
				action.payload.computedContact || currentState.selectedConversation.meta?.cockpit?.contact;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					contact: newContact,
					meta: {
						...currentState.selectedConversation.meta,
						cockpit: {
							...currentState.selectedConversation.meta.cockpit,
							contact: computedContact
						}
					}
				},
				conversations: currentState.conversations.map(conv =>
					conv.header.fID === conversationfID
						? {
								...conv,
								contact: {
									uid: newContact.uid,
									ML_id: newContact.ML_id
								},
								meta: {
									...conv.meta,
									cockpit: {
										...conv.meta.cockpit,
										contact: computedContact
									}
								}
							}
						: conv
				)
			};
		}

		case C.EMPTY_CONV_STATE: {
			const state = action.value;

			return {
				...currentState,
				conversations: [...currentState.conversations.filter(e => e.header.state !== state)]
			};
		}

		case C.ADD_CONTENT: {
			return {
				...currentState,
				addedContent: action.payload
			};
		}

		case C.ADD_BL: {
			const { contentType: mimeType, url } = action.payload?.file || {};
			const bl = { mimeType, url };
			const proof = { ...currentState?.selectedConversation?.context?.proof, bl };
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof
					}
				}
			};
		}

		case C.CLEAR_BL: {
			const proof = { ...currentState?.selectedConversation?.context?.proof };
			delete proof?.bl;
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof
					}
				}
			};
		}

		case C.ADD_POD: {
			const { contentType: mimeType, url } = action.payload?.file || {};
			const pod = { mimeType, url };
			const proof = { ...currentState?.selectedConversation?.context?.proof, pod };
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof
					}
				}
			};
		}

		case C.CLEAR_POD: {
			const proof = { ...currentState?.selectedConversation?.context?.proof };
			delete proof?.pod;
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof
					}
				}
			};
		}

		case "connectionStatus": {
			if (currentState.leftTab === 3) {
				return {
					...currentState,
					conversations: currentState.conversations.map(conv =>
						conv.header.fID === action.fID && conv.meta?.cockpit
							? {
									...conv,
									meta: {
										...conv.meta,
										cockpit: {
											...conv.meta.cockpit,
											connected: action.content.connected
										}
									}
								}
							: conv
					),
					selectedConversation:
						currentState?.selectedConversation?.header?.fID === action.fID
							? {
									...currentState.selectedConversation,
									meta: {
										...currentState.selectedConversation.meta,
										cockpit: {
											...currentState.selectedConversation.meta.cockpit,
											connected: action.content.connected
										}
									}
								}
							: currentState.selectedConversation
				};
			}
			break;
		}

		case C.CLEAR_CONTENT: {
			return {
				...currentState,
				addedContent: null
			};
		}

		case C.SET_TOPIC_DRAFT: {
			return {
				...currentState,
				topicDraft: action.payload
			};
		}

		case C.CONV_SENDING: {
			return {
				...currentState,
				convSending: action.payload
			};
		}

		case C.CONV_LOADING: {
			return {
				...currentState,
				convLoading: action.payload
			};
		}
		case C.HISTORY_LOADING: {
			return {
				...currentState,
				historyLoading: action.payload
			};
		}

		case C.CONV_GET_MID: {
			return {
				...currentState,
				currentMessage: {
					...constructPivot(),
					mID: action.payload
				}
			};
		}

		case C.CONV_SEND_SUCCESS: {
			const messages = [...currentState.selectedConversation?.messages];
			messages.push(action.payload);
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					messages
				},
				attachments: [],
				currentMessage: null
			};
		}

		case C.SET_CONTENT_MESSAGE: {
			if (action.payload || action.payload === "") {
				const currentMessage = {
					...currentState.currentMessage
				};
				if (typeof action.payload === "string") {
					currentMessage.message.payload.text = {
						plain_text: action.payload
					};
					return {
						...currentState,
						currentMessage
					};
				} /* else if (action.payload.type === "richtext") {
					currentMessage.message.payload.text = {
						plain_text: action.payload.text
					};
					return {
						...currentState,
						currentMessage
					};
				} */ else if (action.payload.title) {
					// It's a menu
					currentMessage.message.header.type = "menu";
					currentMessage.message.payload.subject = action.payload.title;
					if (action.payload.choice) {
						currentMessage.message.payload.menu = {
							choices: [...action.payload.choice]
						};
					}
					return {
						...currentState,
						currentMessage
					};
				} else if (action.payload.addressName) {
					currentMessage.message.header.type = "location";
					currentMessage.message.payload.subject = action.payload.addressName;
					currentMessage.message.payload.text = {
						plain_text: action.payload.address
					};
					return {
						...currentState,
						currentMessage,
						attachments: []
					};
				} else if (action.payload.type === "document") {
					const { caption, url, filename } = action.payload;
					const attachments = [
						{
							id: caption,
							name: filename,
							url,
							type: "file",
							progress: 0
						}
					];
					return {
						...currentState,
						currentMessage:
							currentState.currentMessage?.message?.header?.type === "file"
								? constructPivot()
								: currentState.currentMessage,
						attachments
					};
				} else if (action.payload.type === "image") {
					let attachments;
					if (action.payload.file) {
						const { contentType, ...others } = action.payload.file;
						attachments = [
							{
								id: action.payload.caption,
								...others,
								type: "file",
								mimeType: contentType,
								progress: 0
							}
						];
					} else {
						const { caption, url, filename } = action.payload;
						attachments = [
							{
								id: caption,
								name: filename ? filename : caption,
								url,
								type: "file",
								progress: 0,
								mimeType: "image/png" //temporary, waiting for Angelique's feature
							}
						];
					}

					return {
						...currentState,
						currentMessage:
							currentState.currentMessage?.message?.header?.type === "file"
								? constructPivot()
								: currentState.currentMessage,
						attachments
					};
				} else if (action.payload.type === "attachment") {
					const { contentType, ...others } = action.payload;
					let attachments = lod_.cloneDeep(currentState.attachments);
					attachments = [
						{
							id: action.payload.name,
							...others,
							type: "file",
							mimeType: contentType,
							progress: 100
						},
						...attachments
					];
					return {
						...currentState,
						currentMessage:
							currentState.currentMessage?.message?.header?.type === "file"
								? constructPivot()
								: currentState.currentMessage,
						attachments
					};
				} else if (action.payload.type === "variable") {
					/**
					 * For variable attachments
					 */
					const { ...others } = action.payload;
					let attachments = lod_.cloneDeep(currentState.attachments);
					attachments = [
						{
							id: action.payload.name,
							...others,
							type: "variable",
							progress: 100
						},
						...attachments
					];
					return {
						...currentState,
						currentMessage:
							currentState.currentMessage?.message?.header?.type === "file"
								? constructPivot()
								: currentState.currentMessage,
						attachments
					};
				}
			}
			break;
		}

		case C.SCROLL_TO_SELECTED_CONVERSATION: {
			return {
				...currentState,
				scrollToSelectedConversation: true
			};
		}

		case C.CLEAR_SCROLL_TO_SELECTED_CONVERSATION: {
			return {
				...currentState,
				scrollToSelectedConversation: false
			};
		}

		case C.CLEAR_CONTENT_MESSAGE: {
			return {
				...currentState,
				currentMessage: {
					...constructPivot()
				}
			};
		}

		case C.ADD_CONVERSATIONS: {
			const skip = action.payload.skip;
			const limit = action.payload.limit;
			const res = action.payload.res;
			let newState = { ...currentState };
			if (currentState.conversations[skip]) {
				//if some of results already are in the store, we replace instead of push it in the array
				let resIndex = 0;
				for (let i = skip; i <= limit; i++) {
					newState.conversations[i] = res[resIndex];
					resIndex++;
				}
				return {
					...newState,
					conversationsFetched: true
				};
			}

			// CHECK if conversation is already exist in current list of conv

			let currentConvs = lod_.cloneDeep(currentState.conversations);
			let newConvRes = lod_.cloneDeep(action?.payload?.res);

			for (let item of newConvRes) {
				const indexInTab = currentConvs.findIndex(
					itemConv => itemConv?.header?.fID === item?.header?.fID
				);
				if (indexInTab !== -1) {
					// IF element have same fID found, update current conv.
					currentConvs[indexInTab] = item;
				} else {
					// Else, add conv in current list.
					currentConvs.push(item);
				}
			}
			return {
				...currentState,
				conversations: currentConvs,
				conversationsFetched: true
			};
		}

		case C.ADD_CONVERSATIONS_RESOLVED: {
			return {
				...currentState,
				conversations: [action.payload],
				conversationsFetched: true
			};
		}

		case C.UPDATE_CONVERSATIONS: {
			let newConversations = action.payload;
			return { ...currentState, conversations: newConversations, conversationsFetched: true };
		}

		case C.START_TO_FETCH_CONVS: {
			return { ...currentState, conversationsFetched: false /*, conversations: [] */ };
		}

		case C.DELETE_DRAFT: {
			let conversationFID = action.payload.fID;

			let newState = {
				...currentState,
				conversations: currentState.conversations.filter(
					conv => conv?.header?.fID !== conversationFID
				),
				selectedConversation: null,
				nbOnGoingConvs: currentState.nbOnGoingConvs - 1
			};

			return newState;
		}

		case C.SET_CLIPBOARD: {
			let newState = {
				...currentState
			};
			return newState;
		}

		case C.POST_NEW_STATE: {
			//to change and setSelectedConv to null, stay in the same type conv
			let conversationFID = action.payload.conversationFID;
			let state = action.payload.state;
			let substate = action.payload.substate;
			let note = action.payload.note || false;
			let oldState = currentState.selectedConversation.header.state;
			let newState = {
				...currentState,
				conversations: currentState.conversations.filter(
					conv => conv?.header?.fID !== conversationFID
				)
			};
			if (oldState === state) {
				if (note && note.text) {
					newState.leftTab = 1;
					newState.selectedConversation = {
						...currentState.selectedConversation,
						notes: [...currentState.selectedConversation.notes, note]
					};
					return newState;
				} else {
					newState.selectedConversation = null;
					return newState;
				}
			} else {
				if (state === "resolved") {
					newState.selectedConversation = null;
					newState.leftTab = 1;
					newState.nbResolvedConvs = currentState.nbResolvedConvs + 1;
					if (isConversationLive(currentState.selectedConversation.header)) {
						newState.nbLiveConvs = currentState.nbLiveConvs - 1;
						newState.leftTab = 3;
					} else newState.nbOngoingConvs = currentState.nbOngoingConvs - 1;
					return newState;
				} else if (state === "ongoing") {
					newState.selectedConversation = null;
					newState.nbOngoingConvs = currentState.nbOngoingConvs + 1;
					newState.nbResolvedConvs = currentState.nbResolvedConvs - 1;
					return newState;
				} else if (state === "hidden") {
					newState.selectedConversation = null;
					switch (oldState) {
						case "waiting":
							newState.nbWaitingConvs = currentState.nbWaitingConvs - 1;
							break;
						case "ongoing":
							newState.nbOngoingConvs = currentState.nbOngoingConvs - 1;
							break;
						default:
							break;
					}
					return newState;
				} else if (state === "waiting" && oldState === "hidden") {
					newState.selectedConversation = null;
					newState.nbHiddenConvs = currentState.nbHiddenConvs - 1;
					newState.nbCurrentConvs = [
						currentState.nbCurrentConvs[0],
						currentState.nbCurrentConvs[1] - 1
					];
					return newState;
				}
			}
		}

		case C.POST_DRAFT_LOADING:
			return {
				...currentState,
				postDraftLoading: action.payload
			};
		case C.SET_URGENT: {
			return { ...currentState, urgent: action.payload };
		}
		case C.POST_DRAFT: {
			let fID = action.payload.fID;
			let draftAnswer = action.payload.draftAnswer;
			let draftAttachments = action.payload.attachments;
			let draftAnswerParts = action.payload.draftAnswerParts;
			let draftTopic = action.payload.draftTopic;
			let emailCC = action.payload.emailCC;
			let emailBCC = action.payload.emailBCC;
			let engageLinkContact = action.payload.engageLinkContact;

			let updatedConv = lod_.cloneDeep(currentState.selectedConversation);

			/**
			 * Post draft is called when a sendAnswer is performed
			 * -> this is non sense
			 * -> it provoques a null updatedConv !
			 */

			// In this special case we want to update the meta.cockpit.title
			const isConvDraft = updatedConv?.header?.substate === "draft";

			let meta = updatedConv.meta;
			if (isConvDraft && draftTopic) {
				if (draftTopic) {
					meta.cockpit.title = draftTopic;
				}
				if (engageLinkContact) {
					meta.cockpit.contact = engageLinkContact;
				}
			}

			let drafts = updatedConv.drafts;

			if (draftAnswer !== null && draftAnswer !== undefined) {
				drafts.draftAnswer = draftAnswer;
			}
			if (draftAttachments) {
				drafts.attachments = draftAttachments;
			}
			if (draftAnswerParts) {
				drafts.draftAnswerParts = draftAnswerParts;
			}
			if (draftAnswer) {
				drafts.draftTopic = draftTopic;
			}
			if (emailCC) {
				drafts.emailCC = emailCC;
			}
			if (draftAnswer) {
				drafts.emailBCC = emailBCC;
			}
			if (engageLinkContact) {
				drafts.engageLinkContact = engageLinkContact;
			}

			let conversationsList = lod_.cloneDeep(currentState.conversations);

			if (isConvDraft) {
				if (draftTopic) {
					conversationsList = conversationsList.map(conv => {
						if (conv.header.fID !== fID) {
							return conv;
						} else {
							let updateConvInList = lod_.cloneDeep(conv);
							updateConvInList.meta.cockpit.title = draftTopic;
							return updateConvInList;
						}
					});
				}
				if (engageLinkContact) {
					conversationsList = conversationsList.map(conv => {
						if (conv.header.fID !== fID) {
							return conv;
						} else {
							let updateConvInList = lod_.cloneDeep(conv);
							updateConvInList.meta.cockpit.contact = engageLinkContact;
							return updateConvInList;
						}
					});
				}
			}

			return {
				...currentState,
				selectedConversation: { ...currentState.selectedConversation, drafts, meta },
				conversations: conversationsList
			};
		}
		case C.COUNT_HISTORY: {
			let countHistory = action.payload.totalHistoryCount;
			let isHistoryConvOngoingOrWaiting = action.payload.isHistoryConvOngoingOrWaiting;
			return {
				...currentState,
				countHistory,
				isHistoryConvOngoingOrWaiting
			};
		}
		case C.UPDATE_HISTORY: {
			let convHistory = action.payload;
			return {
				...currentState,
				convHistory
			};
		}
		case C.DOWNLOAD_ZIP: {
			return {
				...currentState
			};
		}

		case C.SELECT_DEFAULT_CHANNEL: {
			let channel = action.payload.channel;
			let canOpenEngage = action.payload.openEngage;
			if (channel === currentState.defaultChannel) {
				return {
					...currentState,
					canOpenEngage: canOpenEngage
				};
			} else {
				return {
					...currentState,
					defaultChannel: channel,
					canOpenEngage: canOpenEngage
				};
			}
		}

		case C.RESET_OPEN_ENGAGE: {
			return {
				...currentState,
				canOpenEngage: false
			};
		}

		case C.UPDATE_CONVERSATION_EVENT: {
			let newState = { ...currentState };
			const event = action.payload;
			if (!lod_.has(event, "content") || typeof event?.content !== "object") {
				//Bad format
				return currentState;
			}
			if (!event?.content?.header) {
				//Bad format
				return currentState;
			}
			const userEmail = action.payload.user.email;
			const userRole = action.payload.user.role;
			const userGroup = action.payload.user.groups;
			let isSelectedConversation =
				currentState?.selectedConversation?.header?.fID === event?.content?.header?.fID;
			const convIsLive = isConversationLive(event.content.header); //is live if is (waiting or ongoing) and (channel "LC")
			const type = convIsLive ? "live" : event.content.header.state;
			// If tab is in cockpit, else its in archived
			const IS_COCKPIT_PAGE = PAGES_IN_COCKPIT.includes(type);

			const USER_ON_GOOD_PAGE = IS_COCKPIT_PAGE && !currentState.isArchivedPage;

			let leftTabToBe;

			// BY PASS SET CONV -> event.content
			let byPassSetConv = false;

			if (IS_COCKPIT_PAGE) {
				leftTabToBe = LEFT_TAB_BY_CONVERSATION_TYPE[type];
			} else {
				leftTabToBe = LEFT_TAB_BY_CONVERSATION_TYPE_ARCHIVED[type];
			}

			if (!lod_.isNil(leftTabToBe)) {
				const conversationExistsInCurrentList = currentState.conversations.some(
					conv => conv.header.fID === event?.content?.header?.fID
				);
				/**
				 * condition :
				 * Conversation pass current filter +
				 * Left Tab is correct +
				 * If is a live Tab : it must be a live conv
				 */
				let shouldBeShown =
					conversationPassTheFilters(newState, userEmail, userRole, event.content, userGroup) &&
					leftTabToBe === currentState.leftTab &&
					(leftTabToBe === 3 ? convIsLive : !convIsLive) &&
					USER_ON_GOOD_PAGE;

				// If we're currently fetching conversations, we don't want to update the state
				// because it will be fully updated when the fetch is done
				if (!currentState.conversationsFetched) {
					return currentState;
				}

				if (shouldBeShown) {
					//Conv should be in the current list
					if (conversationExistsInCurrentList) {
						//update conv
						newState = {
							...newState,
							conversations: currentState.conversations.map(conv =>
								conv.header.fID === event?.content?.header?.fID ? event.content : conv
							)
						};
					} else {
						//add Conv + increment
						newState.conversations = [...currentState.conversations, event.content];
						newState.nbCurrentConvs = [newState.nbCurrentConvs[0], newState.nbCurrentConvs[1] + 1];
						newState = updateTotalNbForTheRightTab(event.content.header, newState);
					}
				} else {
					//conversation should not be in current list
					if (conversationExistsInCurrentList) {
						//remove Conv and decrement
						newState.nbCurrentConvs = [newState.nbCurrentConvs[0], newState.nbCurrentConvs[1] - 1];
						newState.conversations = newState.conversations.filter(
							conv => conv.header.fID !== event?.content?.header?.fID
						);
						newState = updateTotalNbForTheRightTab(event.content.header, newState);

						// Do not set the conversation if it is already in the list
						// and it should not be shown. Just unset the selected conversation
						byPassSetConv = true;
					} else {
						//increment leftTabToBe if leftTabToBe !== leftTab
						if (leftTabToBe !== currentState.leftTab) {
							// TODO : AND IF IS A NEW CONV, NOT AN UPDATE
							newState = updateTotalNbForTheRightTab(event.content.header, newState);
						}
					}
				}
			}
			//update conv if is selected
			if (isSelectedConversation) {
				if (
					event.content.header.state === "resolved" &&
					event.content.header.substate === "resolved" &&
					(action?.subtype === "rejectConv" || action?.subtype === "updateConvState")
				) {
					newState.selectedConversation = null;
				} else {
					if (byPassSetConv) {
						// If we're bypassing => unselected the conversation
						newState.selectedConversation = null;
					} else {
						// If we're not bypassing => update the conversation
						newState.selectedConversation = event.content;
					}
				}
			}
			return newState;
		}

		case C.UPDATE_CONVERSATION_NOTES_EVENT: {
			let newState = { ...currentState };
			const event = action.payload;
			// Format check
			if (!lod_.has(event, "content") || typeof event?.content !== "object") {
				//Bad format
				return currentState;
			}
			if (!event?.content?.header) {
				//Bad format
				return currentState;
			}

			let isSelectedConversation =
				currentState?.selectedConversation?.header?.fID === event?.content?.header?.fID;

			// Update the notes part only if the conversation is selected
			if (isSelectedConversation) {
				newState.selectedConversation = event.content;
			}

			return newState;
		}

		case C.UPDATE_MESSAGE_STATE_EVENT: {
			// Update the message stat only if it is in selected conversation
			let matchConvID =
				action?.payload?.convID &&
				currentState?.selectedConversation?._id === action.payload.convID;
			let matchConvFID =
				action?.payload?.convFID &&
				currentState?.selectedConversation?.header?.fID === action.payload.convFID;
			if (matchConvID || matchConvFID) {
				let updatedConv = currentState.selectedConversation;

				if (Object.keys(updatedConv.messages).includes(String(action.payload.msgIndex))) {
					updatedConv.messages[action.payload.msgIndex].header.state = action.payload.state;
				}

				return {
					...currentState,
					selectedConversation: updatedConv
				};
			}

			return currentState;
		}
		case C.SET_CONV_INTENT_FILTER: {
			return { ...currentState, convIntentFilter: action.payload };
		}
		case C.SET_CONV_GROUP_FILTER: {
			return { ...currentState, convGroupFilter: action.payload };
		}
		case C.SET_CONV_USER_FILTER: {
			return { ...currentState, convUserFilter: action.payload };
		}
		case C.SET_CONV_DIRECTION_FILTER: {
			return { ...currentState, convDirectionFilter: action.payload };
		}
		case C.SET_CONV_PATH_CONTEXT_FILTER: {
			return { ...currentState, convPathContextFilter: action.payload };
		}
		case C.SET_CONV_NO_BOT_FILTER: {
			return {
				...currentState,
				convSupervisorFilter: false,
				convAgentFilter: false,
				convNoBotFilter: action.payload
			};
		}

		case C.SET_CONV_SEARCH_FILTER: {
			return { ...currentState, convSearchFilter: action.payload };
		}
		case C.SET_CONV_START_DATE_FILTER: {
			return { ...currentState, convStartDateFilter: action.payload };
		}
		case C.SET_CONV_END_DATE_FILTER: {
			return { ...currentState, convEndDateFilter: action.payload };
		}
		case C.SET_CONV_PRIORITY_FILTER: {
			return { ...currentState, convPriorityFilter: action.payload };
		}
		case C.SET_CONV_SENTIMENT_FILTER: {
			return { ...currentState, convSentimentFilter: action.payload };
		}
		case C.SET_CONV_SUBSTATE_FILTER: {
			return { ...currentState, convSubstateFilter: action.payload };
		}
		case C.SET_CONV_CONNECTED_FILTER: {
			return { ...currentState, convConnectedFilter: action.payload };
		}
		case C.SET_CONV_ESCALATE_FILTER: {
			return { ...currentState, convEscalationFilter: action.payload };
		}
		case C.SET_CONV_LANGUAGE_FILTER: {
			return { ...currentState, convLanguageFilter: action.payload };
		}
		case C.SET_CONV_CHANNEL_FILTER: {
			return { ...currentState, convChannelFilter: action.payload };
		}
		case C.RESET_FILTERS: {
			return {
				...currentState,
				convIntentFilter: [],
				convGroupFilter: [],
				convUserFilter: [],
				convSearchFilter: "",
				convStartDateFilter: null,
				convEndDateFilter: null,
				convPriorityFilter: null,
				convSentimentFilter: null,
				convSubstateFilter: null,
				convConnectedFilter: true, //the default value
				convEscalationFilter: null,
				convLanguageFilter: null,
				convChannelFilter: null,
				convAgentFilter: true,
				convSupervisorFilter: true
			};
		}

		case C.LOAD_FILTERS: {
			return {
				...currentState,
				convAscFilter: action.payload.filters.convAscFilter
					? action.payload.filters.convAscFilter
					: action.payload.defaultListDisplayOrder,
				convIntentFilter: action.payload.filters.convIntentFilter,
				convGroupFilter: action.payload.filters.convGroupFilter,
				convUserFilter: action.payload.filters.convUserFilter,
				convSearchFilter: action.payload.filters.convSearchFilter,
				convStartDateFilter: action.payload.filters.convStartDateFilter,
				convEndDateFilter: action.payload.filters.convEndDateFilter,
				convPriorityFilter: action.payload.filters.convPriorityFilter,
				convSentimentFilter: action.payload.filters.convSentimentFilter,
				convSubstateFilter: action.payload.filters.convSubstateFilter,
				convConnectedFilter: action.payload.filters.convConnectedFilter,
				convEscalationFilter: action.payload.filters.convEscalationFilter,
				convLanguageFilter: action.payload.filters.convLanguageFilter,
				convChannelFilter: action.payload.filters.convChannelFilter,
				convSupervisorFilter: action.payload.filters.convSupervisorFilter,
				convAgentFilter: action.payload.filters.convAgentFilter
			};
		}

		case C.ADD_CONVERSATION_EVENT: {
			let newConversationEvent = action.payload.event.content;
			let assIDFromEvent = newConversationEvent.header.assistantID;
			let currentAssistantID = action.payload.event.assistantID;
			const userEmail = action.payload.user.email;
			const userRole = action.payload.user.role;
			const userGroup = action.payload.user?.groups;

			const type = getConversationTypeByLeftTab(
				currentState.leftTab,
				currentState.isCockpitArchived
			);
			const isLive = isConversationLive(newConversationEvent.header);
			let hasToBeInCurrentTab = false;
			if (
				type === "live" &&
				isLive &&
				(newConversationEvent.header.state === "waiting" ||
					newConversationEvent.header.state === "ongoing")
			) {
				hasToBeInCurrentTab = true;
			} else if (newConversationEvent.header.state === type) {
				hasToBeInCurrentTab = true;
			}
			if (!hasToBeInCurrentTab) {
				return updateTotalNbForTheRightTab(newConversationEvent.header, currentState);
			} else {
				//if in currentTab
				if (
					conversationPassTheFilters(
						currentState,
						userEmail,
						userRole,
						newConversationEvent,
						userGroup
					)
				) {
					let sameConvIndex = currentState.conversations.findIndex(
						conv => conv.header.fID === newConversationEvent.header.fID
					);
					if (sameConvIndex === -1) {
						// Conv is not existing in current conversation list so add it
						if (assIDFromEvent === currentAssistantID) {
							let newState = updateTotalNbForTheRightTab(newConversationEvent.header, currentState);
							// Check assistant so we don't mix
							//push in top or bottom of list by  convAscFilter
							let conversations;
							if (currentState.convAscFilter) {
								conversations = [newConversationEvent, ...currentState.conversations];
							} else conversations = [...currentState.conversations, newConversationEvent];
							return {
								...newState,
								conversations,
								nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] + 1]
							};
						}
					} else {
						//if existing but obsolete replace it by new conv, otherwise make nothing
						if (
							new Date(currentState.conversations[sameConvIndex].header.lastUpdate) <
							new Date(newConversationEvent.header.lastUpdate)
						) {
							//if the conversation to updateupdate is the selectedConversation, then update it as well

							if (
								currentState.selectedConversation &&
								currentState.selectedConversation.header.fID === newConversationEvent.header.fID
							) {
								return {
									...currentState,
									selectedConversation: newConversationEvent,
									conversations: currentState.conversations.map(conv =>
										conv.header.fID === newConversationEvent.header.fID
											? newConversationEvent
											: conv
									)
								};
							} else {
								return {
									...currentState,
									conversations: currentState.conversations.map(conv =>
										conv.header.fID === newConversationEvent.header.fID
											? newConversationEvent
											: conv
									)
								};
							}
						}
					}
				}
			}

			return currentState;
		}

		case C.ADD_MESSAGE_EVENT: {
			//TODO convID to convFID ?
			let newMessageEvent = action.payload;
			if (
				lod_.has(currentState, "selectedConversation._id") &&
				currentState.selectedConversation._id === newMessageEvent.conversationID
			) {
				// Note: ! Attention message could be unorganized, eventually sort by timestamp
				return {
					...currentState,
					selectedConversation: {
						...currentState.selectedConversation,
						messages: [...currentState.selectedConversation.messages, newMessageEvent.content]
					}
				};
			}
		}

		case C.ASSIGN_CONVERSATION: {
			let conversationToAssign = action.payload.conv;
			let email = action.payload.email;
			let switchTabOnAssignation = action.payload.switchTabOnAssignation;
			let nbs = {};
			const convState = action.payload.conv.header.state;

			// Recalculate the number of conversations for each types
			if (!isConversationLive(action.payload.conv.header)) {
				// don't update nbs if it's a live conv (because from live to live)
				nbs.nbOngoingConvs = currentState.nbOngoingConvs + 1;

				if (convState === "waiting") {
					nbs = { ...nbs, nbWaitingConvs: currentState.nbWaitingConvs - 1 };
				} else if (convState === "archived") {
					nbs = { ...nbs, nbArchivedConvs: currentState.nbArchivedConvs - 1 };
				} else if (convState === "resolved") {
					nbs = { ...nbs, nbResolvedConvs: currentState.nbResolvedConvs - 1 };
				}
				return {
					...currentState,
					...nbs,
					selectedConversation: switchTabOnAssignation ? currentState.selectedConversation : null,
					conversations: switchTabOnAssignation
						? currentState.conversations.map(conv =>
								conv.header.fID === conversationToAssign.header.fID
									? {
											...conv,
											agent: { ...conv.agent, isBot: false, uid: email },
											header: {
												...conv.header,
												state: "ongoing",
												status: "intercepted",
												humanRequest: false
											}
										}
									: conv
							)
						: currentState.conversations.filter(
								conv => conv.header.fID !== conversationToAssign.header.fID
							)
				};
			} else {
				return {
					...currentState,
					conversations: currentState.conversations.map(conv =>
						conv.header.fID === conversationToAssign.header.fID
							? {
									...conv,
									agent: { ...conv.agent, isBot: false, uid: email },
									header: {
										...conv.header,
										state: "ongoing",
										status: "intercepted",
										humanRequest: false
									}
								}
							: conv
					)
				};
			}
		}

		case C.REJECT_CONVERSATION: {
			let payload = action.payload;
			let { drafts, ...rest } = payload.conv;
			let conversationToReject = rest;

			let stateConv = conversationToReject.header.state;
			const isLive = isConversationLive(conversationToReject.header);
			let newState = {
				...currentState
			};
			/**
			 * Keep the substate only in certain circumstances
			 */
			let targetSubstate = conversationToReject.header.substate;
			const newCurrentConvsNb = [newState.nbCurrentConvs[0], newState.nbCurrentConvs[1] - 1];
			if (stateConv === "ongoing") {
				const nbOnGoing = currentState.nbOngoingConvs - 1;
				const nbWaiting = currentState.nbWaitingConvs + 1;

				if (targetSubstate === "watchlist" || targetSubstate === "resolved")
					targetSubstate = "expecting_answer";
				if (
					currentState.selectedConversation &&
					currentState.selectedConversation.header.fID === conversationToReject.header.fID
				) {
					newState.selectedConversation = null;
				}
				if (!isLive) {
					newState.nbWaitingConvs = nbWaiting;
					newState.nbOngoingConvs = nbOnGoing;
					newState.nbCurrentConvs = newCurrentConvsNb;
				}
			} else if (stateConv === "resolved") {
				const nbResolved = currentState.nbResolvedConvs - 1;
				const nbWaiting = currentState.nbWaitingConvs + 1;

				if (targetSubstate === "watchlist" || targetSubstate === "resolved")
					targetSubstate = "expecting_answer";

				if (!isLive) {
					newState.nbWaitingConvs = nbWaiting;
					newState.nbResolvedConvs = nbResolved;
					newState.nbCurrentConvs = newCurrentConvsNb;
				}
			}
			if (
				!isLive ||
				(isLive && currentState.convAgentFilter && currentState.convSupervisorFilter)
			) {
				newState.conversations = currentState.conversations.filter(
					conv => conv.header.fID !== conversationToReject.header.fID
				);
				if (isLive) newState.nbCurrentConvs = newCurrentConvsNb;
			} else {
				newState.conversations = currentState.conversations.map(conv =>
					conv.header.fID === conversationToReject.header.fID
						? {
								...conv,
								header: {
									...conv.header,
									state: "waiting"
								}
							}
						: conv
				);
			}
			return newState;
		}

		case C.SEND_ANSWER: {
			let data = action.payload;
			let type = data.type;
			// const nbOnGoing = currentState.nbOngoingConvs - 1;
			// const nbResolved = currentState.nbResolved + 1;

			let msgHeader = {
				source: "agent",
				datetime: data.datetime,
				ts: data.ts,
				channel: data.channel,
				language: data.language,
				extra: data.extra
			};
			let oldState = currentState.selectedConversation.header.state;
			let state = data.state;
			let substate = data.substate;
			let topic = data.topic;
			/* if (data.type === "ticket") {
        msgHeader = { ...msgHeader, state: "sending" }
      } */

			let newMessage = {
				header: msgHeader,
				agent: {
					name: data.name,
					uid: data.uid
				},
				meta: {
					intent: "",
					intentScore: "",
					sentiment: ""
				},
				body: {
					topic: topic,
					repliedAnswer: data.repliedAnswer
				},
				sent_attachments: data.sent_attachments
			};
			if (oldState === state) {
				return {
					...currentState,

					conversations: currentState.conversations.map(conv =>
						conv?.header?.fID === data.fID
							? {
									...conv,
									header: {
										...currentState.selectedConversation.header,
										state: state,
										substate: substate
									},
									meta: {
										...currentState.selectedConversation.meta,
										cockpit: {
											...currentState.selectedConversation.meta.cockpit,
											contact:
												data?.engageLinkContact ||
												currentState?.selectedConversation?.meta?.cockpit?.contact
										}
									}
								}
							: conv
					),
					selectedConversation: null
				};
			} else {
				if (state === "resolved") {
					return {
						...currentState,
						selectedConversation: null,
						leftTab: 1,
						nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] - 1],

						conversations: currentState.conversations.filter(conv => conv.header.fID !== data.fID),
						nbOngoingConvs: currentState.nbOngoingConvs - 1,
						nbResolvedConvs: currentState.nbResolvedConvs + 1
					};
				} else if (state === "ongoing") {
					return {
						...currentState,
						nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] - 1],

						selectedConversation: null,
						conversations: currentState.conversations.filter(conv => conv.header.fID !== data.fID),
						nbOngoingConvs: currentState.nbOngoingConvs + 1,
						nbResolvedConvs: currentState.nbResolvedConvs - 1
					};
				} else if (type === "conversational") {
					//case conversational
					return {
						...currentState,
						nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] - 1],
						conversations: currentState.conversations.filter(conv => conv.header.fID !== data.fID),
						selectedConversation: {
							...currentState.selectedConversation,
							messages: [...currentState.selectedConversation.messages, newMessage]
						}
					};
				}
			}
		}

		case C.UPDATE_DOCUMENTS: {
			let documents = action.payload;
			return { ...currentState, documents: documents };
		}

		case C.POST_NOTE: {
			let note = action.payload.note;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					notes: [...currentState.selectedConversation.notes, note]
				}
			};
		}

		case C.DELETE_NOTE: {
			let noteToDelete = action.payload.note;
			let updateConv = lod_.cloneDeep(currentState.selectedConversation);

			if (updateConv.notes) {
				updateConv.notes = updateConv.notes.filter(note => {
					if (
						note.author === noteToDelete.author &&
						note.datetime === noteToDelete.datetime &&
						note.text === noteToDelete.text
					) {
						return null;
					} else {
						return note;
					}
				});
			}

			return {
				...currentState,
				selectedConversation: updateConv
			};
		}

		case C.ESCALATE_CONVERSATION:
			{
				let conversationFID = action.payload.fID;
				let escalationGroup = action.payload.escalationGroup;
				let leftGroup = action.payload.leftGroup;
				let isUserMemberOfEscalationGroup = action.payload.isUserMemberOfEscalationGroup;
				let stateFromPayload = action.payload.state;

				if (isUserMemberOfEscalationGroup) {
					return {
						...currentState,
						nbOngoingConvs:
							stateFromPayload === "ongoing"
								? currentState.nbOngoingConvs - 1
								: currentState.nbOngoingConvs,
						nbResolvedConvs:
							stateFromPayload === "resolved"
								? currentState.nbResolvedConvs - 1
								: currentState.nbResolvedConvs,
						selectedConversation: {
							...currentState.selectedConversation,
							agent: {
								...currentState.selectedConversation.agent,
								name: "",
								uid: ""
							},
							header: {
								...currentState.selectedConversation.header,
								state: "waiting",
								status: "active",
								isEscalated: true,
								escalationGroup: escalationGroup
							}
						},
						conversations: currentState.conversations.map(conv =>
							conv.header.fID === conversationFID
								? {
										...conv,
										agent: { ...conv.agent, uid: "" },
										header: {
											...conv.header,
											state: "waiting",
											status: "active",
											isEscalated: true,
											escalationGroup: escalationGroup
										}
									}
								: conv
						)
					};
				} else {
					//just remove conv

					if (stateFromPayload === "resolved") {
						return {
							...currentState,
							nbResolvedConvs: currentState.nbResolvedConvs - 1,
							selectedConversation: null,
							conversations: currentState.conversations.filter(
								conv => conv.header.fID !== conversationFID
							)
						};
					} else if (stateFromPayload === "ongoing") {
						return {
							...currentState,
							nbOngoingConvs: currentState.nbOngoingConvs - 1,
							selectedConversation: null,
							conversations: currentState.conversations.filter(
								conv => conv.header.fID !== conversationFID
							)
						};
					}
				}
			}
			break;

		case C.DEESCALATE_CONVERSATION: {
			{
				let conversationFID = action.payload.fID;
				let isUserMemberOfDeescalationGroup = action.payload.isUserMemberOfDeescalationGroup;
				let stateFromPayload = action.payload.state;

				if (isUserMemberOfDeescalationGroup) {
					return {
						...currentState,
						selectedConversation: {
							...currentState.selectedConversation,
							agent: {
								...currentState.selectedConversation.agent,
								name: "",
								uid: ""
							},
							header: {
								...currentState.selectedConversation.header,
								state: "waiting",
								status: "active",
								isEscalated: false,
								escalationGroup: ""
							}
						},

						conversations: currentState.conversations.map(conv =>
							conv.header.fID === conversationFID
								? {
										...conv,
										agent: { ...conv.agent, name: "", uid: "" },
										header: {
											...conv.header,
											state: "waiting",
											status: "active",
											isEscalated: false,
											escalationGroup: ""
										}
									}
								: conv
						)
					};
				} else {
					if (stateFromPayload === "resolved") {
						return {
							...currentState,
							nbOngoingConvs: currentState.nbOnGoingConvs - 1,
							selectedConversation: null,
							conversations: currentState.conversations.filter(
								conv => conv.header.fID !== conversationFID
							)
						};
					}
					if (stateFromPayload === "ongoing") {
						return {
							...currentState,
							nbWaitingConvs: currentState.nbWaitingConvs - 1,
							selectedConversation: null,
							conversations: currentState.conversations.filter(
								conv => conv.header.fID !== conversationFID
							)
						};
					}
				}
			}
			break;
		}

		case C.REDIRECT_TO_USER: {
			// Auth-dev is sending a FrontEvent update_conversation so it should update
			// The only thing we want here is to remove the set the selected conversation to null

			return {
				...currentState,
				selectedConversation: null
			};
		}

		case C.REDIRECT_TO_GROUP: {
			let conversationFID = action.payload.conversationFID;
			let isUserMemberOfRedirectionGroup = action.payload.isUserMemberOfRedirectionGroup;
			let targetGroup = action.payload.targetGroup;
			let oldState = action.payload.oldState;

			if (isUserMemberOfRedirectionGroup) {
				return {
					...currentState,
					nbOngoingConvs:
						oldState === "ongoing" ? currentState.nbOngoingConvs - 1 : currentState.nbOngoingConvs,
					nbResolvedConvs:
						oldState === "resolved"
							? currentState.nbResolvedConvs - 1
							: currentState.nbResolvedConvs,
					selectedConversation: {
						...currentState.selectedConversation,
						agent: {
							...currentState.selectedConversation.agent,
							name: "",
							uid: ""
						},
						header: {
							...currentState.selectedConversation.header,
							state: "waiting",
							isEscalated: false,
							status: "active",
							resolverGroup: targetGroup
						}
					},
					conversations: currentState.conversations.map(conv =>
						conv.header.fID === conversationFID
							? {
									...conv,
									agent: { ...conv.agent, name: "", uid: "" },
									header: {
										...conv.header,
										state: "waiting",
										status: "active",
										isEscalated: false,
										resolverGroup: targetGroup
									}
								}
							: conv
					)
				};
			} else {
				//just remove conv
				return {
					...currentState,
					selectedConversation: null,
					conversations: currentState.conversations.filter(
						conv => conv.header.fID !== conversationFID
					)
				};
			}
		}

		case C.UPDATE_ESCALATION_EVENT: {
			let conversation = action.payload.conversation;
			let isUserMemberOfTargetGroup = action.payload.isUserMemberOfTargetGroup;
			let newState = { ...currentState };
			const userEmail = action.payload.user.email;
			const userRole = action.payload.user.role;
			const userGroup = action.payload.user?.groups;

			if (isUserMemberOfTargetGroup) {
				let sameConvIndex = currentState.conversations.findIndex(
					conv => conv.header.fID === conversation.header.fID
				);
				//if existing replace it by new conv, otherwise create new one
				if (sameConvIndex !== -1) {
					if (
						currentState.selectedConversation &&
						currentState.selectedConversation.header.fID === conversation.header.fID
					) {
						return {
							...currentState,
							selectedConversation: { ...conversation },
							conversations: currentState.conversations.map(conv =>
								conv.header.fID === conversation.header.fID ? conversation : conv
							)
						};
					} else {
						return {
							...currentState,
							conversations: currentState.conversations.map(conv =>
								conv.header.fID === conversation.header.fID ? conversation : conv
							)
						};
					}
				} else {
					newState = updateTotalNbForTheRightTab(conversation.header, currentState);
					if (
						!conversationPassTheFilters(currentState, userEmail, userRole, conversation, userGroup)
					) {
						return newState;
					} //TODOFA HERE CHECK ASSISTANT
					else
						return {
							...newState,
							nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] + 1],
							conversations: [...currentState.conversations, conversation]
						};
				}
			} else {
				//just remove conv
				newState = updateTotalNbForTheRightTab(conversation.header, currentState, true);
				if (
					currentState.selectedConversation &&
					currentState.selectedConversation.header.fID === conversation.header.fID
				) {
					newState["selectedConversation"] = null;
				}
				if (
					!conversationPassTheFilters(currentState, userEmail, userRole, conversation, userGroup)
				) {
					return newState;
				} else
					return {
						...newState,
						nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] - 1],
						conversations: currentState.conversations.filter(
							conv => conv.header.fID !== conversation.header.fID
						)
					};
			}
		}

		case C.REDIRECT_CONVERSATION_EVENT: {
			let conversation = action.payload.conversation;
			let isUserMemberOfTargetGroup = action.payload.isUserMemberOfTargetGroup;
			const userEmail = action.payload.user.email;
			const userRole = action.payload.user.role;
			const userGroup = action.payload.user?.groups;

			if (isUserMemberOfTargetGroup) {
				let sameConvIndex = currentState.conversations.findIndex(
					conv => conv.header.fID === conversation.header.fID
				);
				//if existing replace it by new conv, otherwise create new one
				if (sameConvIndex !== -1) {
					let newState = { ...currentState };
					if (!conversationPassTheFilters(newState, userEmail, userRole, conversation, userGroup)) {
						newState.nbCurrentConvs = [newState.nbCurrentConvs[0], newState.nbCurrentConvs[1] - 1];
						newState.conversations = newState.conversations.filter(
							conv => conv.header.fID !== conversation.header.fID
						);
						return newState;
					}
					if (currentState.selectedConversation?.header.fID === conversation.header.fID) {
						newState.selectedConversation = { ...conversation };
					}
					return {
						...newState,
						conversations: currentState.conversations.map(conv =>
							conv.header.fID === conversation.header.fID ? conversation : conv
						)
					};
				} else {
					let newState = { ...currentState };
					newState = updateTotalNbForTheRightTab(conversation.header, newState);
					if (conversationPassTheFilters(newState, userEmail, userRole, conversation, userGroup)) {
						newState.nbCurrentConvs = [
							currentState.nbCurrentConvs[0],
							currentState.nbCurrentConvs[1] + 1
						];
						newState.conversations = [...currentState.conversations, conversation];
					}
					return {
						...newState
					};
				}
			} else {
				let newState = { ...currentState };
				//just remove conv
				let sameConvIndex = currentState.conversations.findIndex(
					conv => conv.header.fID === conversation.header.fID
				);
				if (
					currentState.selectedConversation &&
					currentState.selectedConversation.header.fID === conversation.header.fID
				) {
					newState.selectedConversation = null;
				}
				newState = updateTotalNbForTheRightTab(conversation.header, newState, true);
				if (sameConvIndex !== -1) {
					newState.nbCurrentConvs = [
						currentState.nbCurrentConvs[0],
						currentState.nbCurrentConvs[1] - 1
					];
					newState.conversations = currentState.conversations.filter(
						conv => conv.header.fID !== conversation.header.fID
					);
				}
				return {
					...newState
				};
			}
		}

		case C.ADD_ATTACHMENT: {
			const attachments = [
				...currentState.attachments,
				{
					...action.payload,
					progress: 0
				}
			];

			return { ...currentState, attachments };
		}

		case C.UPDATE_ATTACHMENT_PROGRESS: {
			const progress = action.payload.progress;
			const id = action.payload.id;

			let attachments = [...currentState.attachments];
			let index = currentState.attachments.findIndex(el => el.id === id);
			let current_attachment = attachments[index];
			current_attachment = { ...current_attachment, progress };
			attachments[index] = current_attachment;

			return { ...currentState, attachments };
		}

		case C.CLEAR_ATTACHMENTS: {
			return { ...currentState, attachments: [] };
		}

		case C.DELETE_ATTACHMENT: {
			const MAX_PROGRESS = 100;
			const id = action.payload.id;

			let index = currentState.attachments.findIndex(el => el.id === id);
			let current_attachment = currentState.attachments[index];

			if (current_attachment.progress < MAX_PROGRESS && current_attachment.source) {
				current_attachment.source.cancel();
			}

			let attachments = [...currentState.attachments];
			attachments.splice(index, 1);
			return { ...currentState, attachments };
		}

		case C.REMOVE_ATTACHMENT_WHEN_ERROR: {
			const id = action.payload.id;

			let index = currentState.attachments.findIndex(el => el.id === id);

			let attachments = [...currentState.attachments];
			attachments.splice(index, 1);
			return { ...currentState, attachments };
		}
		case C.SET_CURRENT_CONVS_NUMBERS: {
			return { ...currentState, nbCurrentConvs: action.payload };
		}
		case C.SET_CONVS_NUMBERS: {
			let nbs = action.payload;
			let newState = { ...currentState };
			if (typeof nbs.waiting !== "undefined") newState.nbWaitingConvs = nbs.waiting;
			if (typeof nbs.ongoing !== "undefined") newState.nbOngoingConvs = nbs.ongoing;
			if (typeof nbs.resolved !== "undefined") newState.nbResolvedConvs = nbs.resolved;
			if (typeof nbs.live !== "undefined") newState.nbLiveConvs = nbs.live;
			if (typeof nbs.archived !== "undefined") newState.nbArchivedConvs = nbs.archived;
			if (typeof nbs.hidden !== "undefined") newState.nbHiddenConvs = nbs.hidden;
			return newState;
		}

		case C.SET_ASC_FILTER: {
			return { ...currentState, convAscFilter: action.payload };
		}
		case C.SET_SUPERVISOR_FILTER: {
			return {
				...currentState,
				convSupervisorFilter: action.payload.value,
				convNoBotFilter: action.payload.noBot
			};
		}
		case C.SET_AGENT_FILTER: {
			return {
				...currentState,
				convAgentFilter: action.payload.value,
				convNoBotFilter: action.payload.noBot
			};
		}

		case C.SET_CONV_AGENT_FETCHED_ONGOING: {
			let isAlreadyFetched = currentState.convAgentFetchedOngoing;
			let value = action.payload;

			if (!value) {
				return { ...currentState, convAgentFetchedOngoing: action.payload };
			} else if (!isAlreadyFetched) {
				return { ...currentState, convAgentFetchedOngoing: action.payload };
			} else {
				return { ...currentState };
			}
		}
		case C.SET_CONV_AGENT_FETCHED_RESOLVED: {
			let isAlreadyFetched = currentState.convAgentFetchedResolved;
			if (!isAlreadyFetched) {
				return { ...currentState, convAgentFetchedResolved: action.payload };
			} else {
				return { ...currentState };
			}
		}

		case C.SELECT_CONVERSATION: {
			return {
				...currentState,
				selectedConversation: action.payload,
				currentMessage: currentState.currentMessage
					? {
							...currentState.currentMessage,
							fID: action.payload.header.fID
						}
					: null
			};
		}

		case C.CLEAR_CONVERSATION: {
			return { ...currentState, selectedConversation: null };
		}

		case C.EMPTY_HISTORY: {
			return { ...currentState, convHistory: [] };
		}

		case C.UPDATE_CONTEXT: {
			const updatedField = action.payload;
			const fID = action.payload.id;
			return {
				...currentState,
				selectedConversation: currentState.selectedConversation.header.fID === fID && {
					...currentState.selectedConversation,
					...updatedField
				}
			};
		}

		case C.DELETE_CONVERSATION: {
			const convID = action.payload;

			if (
				currentState.selectedConversation &&
				currentState.selectedConversation.header.fID === convID
			) {
				return {
					...currentState,
					selectedConversation: null,
					conversations: currentState.conversations.filter(conv => conv.header.fID !== convID)
				};
			} else {
				return {
					...currentState,
					conversations: currentState.conversations.filter(conv => conv.header.fID !== convID)
				};
			}
		}

		case C.DELETE_CONVERSATIONS: {
			const convsID = action.payload;

			if (convsID?.data?.fIDs && !lod_.isEmpty(convsID.data.fIDs)) {
				return {
					...currentState,
					conversations: currentState.conversations.filter(
						conv => !convsID.data.fIDs.includes(conv.header.fID)
					)
				};
			} else {
				return {
					...currentState
				};
			}
		}

		case C.SET_IS_COLD: {
			return { ...currentState, isCold: action.payload };
		}

		case C.SET_LEFT_TAB: {
			return { ...currentState, leftTab: action.payload };
		}

		case C.SET_COLD_CONVS_NUMBER: {
			return { ...currentState, nbColdConvs: action.payload };
		}

		case C.GET_FEEDBACKS: {
			let feedbacks = action.payload;
			return {
				...currentState,
				feedbacks
			};
		}

		case C.UPDATE_FEEDBACK: {
			let data = action.payload;
			let feedbackToUpdate = data.updatedFeedback;

			let justRemoveFeedback = feedbackToUpdate.feedbackIdToRemove !== undefined;
			if (justRemoveFeedback) {
				feedbackToUpdate._id = feedbackToUpdate.feedbackIdToRemove;
			}

			let updatedFeedbacks = currentState.feedbacks;

			let idxToRemove = -1;

			for (let idx in updatedFeedbacks) {
				let feed = updatedFeedbacks[idx];
				if (feed._id === feedbackToUpdate._id) {
					idxToRemove = parseInt(idx);
					break;
				}
			}

			if (idxToRemove !== -1) {
				updatedFeedbacks.splice(idxToRemove, 1);
				if (!justRemoveFeedback) {
					updatedFeedbacks.push(feedbackToUpdate);
				}
			}

			return {
				...currentState,
				feedbacks: updatedFeedbacks
			};
		}

		case C.UPDATE_MESSAGE_INTENT: {
			let data = action.payload;
			let updatedConversation = currentState.selectedConversation;

			// Update the list of conversation
			let updateConversationList = currentState.conversations.map(conv => {
				if (conv.header.fID === data.fID) {
					if (data.conversationMetaCockpitTitle) {
						lod_.set(conv, "meta.cockpit.title", data.conversationMetaCockpitTitle);
					}
				}
				return conv;
			});

			// Update the selected conversation if needed
			let selectedConversation = currentState.selectedConversation;
			if (
				selectedConversation &&
				lod_.has(selectedConversation, "header.fID") &&
				selectedConversation.header.fID === data.fID
			) {
				if (data.conversationMetaCockpitTitle) {
					lod_.set(selectedConversation, "meta.cockpit.title", data.conversationMetaCockpitTitle);
				}
				selectedConversation.messages = selectedConversation.messages.map(message => {
					if (message.header.mID === data.mID) {
						message.meta.intent = data.intent;
					}
					return message;
				});
			}

			return {
				...currentState,
				conversations: updateConversationList,
				selectedConversation: selectedConversation
			};
		}

		case C.UPDATE_CONV_PRIORITY: {
			let data = action.payload;

			// Update the list of conversation
			let updateConversationList = currentState.conversations.map(conv => {
				if (conv.header.fID === data.fID) {
					conv.meta.priority = data.priority;
				}
				return conv;
			});

			// Update the selected conversation if needed
			let selectedConversation = currentState.selectedConversation;
			if (
				selectedConversation &&
				lod_.has(selectedConversation, "header.fID") &&
				selectedConversation.header.fID === data.fID
			) {
				selectedConversation.meta.priority = data.priority;
			}

			return {
				...currentState,
				conversations: updateConversationList,
				selectedConversation: selectedConversation
			};
		}

		/**
		 * Update conversations's state and substate
		 *
		 * payload : {
		 * 	conversation: conversation updated
		 *	oldState: old state
		 * }
		 */
		case C.UPDATE_CONV_STATE: {
			const conversation = action.payload.conversation;
			const oldState = action.payload.oldState;

			let fieldsToUpdate = {};

			const userEmail = action.payload.user?.email;
			const userRole = action.payload.user?.role;
			const userGroup = action.payload.user?.groups;

			let newConversations = currentState.conversations;

			//conversation should be removed from the list if don't pass the filters or if the state changed
			if (
				!conversationPassTheFilters(currentState, userEmail, userRole, conversation, userGroup) ||
				getConversationTypeByLeftTab(currentState.leftTab, currentState.isCockpitArchived) !==
					conversation.header.state
			) {
				newConversations = newConversations.filter(
					conv => conv?.header?.fID !== conversation.header.fID
				);
				if (currentState.nbCurrentConvs[0] === oldState) {
					fieldsToUpdate.nbCurrentConvs = [
						currentState.nbCurrentConvs[0],
						currentState.nbCurrentConvs[1] - 1
					];
				}
				/**
				 * Update new tab
				 */
				if (
					isConversationLive({
						state: conversation.header.state,
						type: conversation.header.type,
						channel: conversation.header.channel
					})
				) {
					fieldsToUpdate = {
						...fieldsToUpdate,
						nbLiveConvs: currentState.nbLiveConvs + 1
					};
				}
				//if conversation is on waiting
				else if (conversation.header.state === "waiting") {
					fieldsToUpdate = {
						...fieldsToUpdate,
						nbWaitingConvs: currentState.nbWaitingConvs + 1
					};
				}
				//if conversation state = "ongoing"
				else if (conversation.header.state === "ongoing") {
					fieldsToUpdate = {
						...fieldsToUpdate,
						nbOngoingConvs: currentState.nbOngoingConvs + 1
					};
				}
				//if conversation state = "resolved"
				else if (conversation.header.state === "resolved") {
					fieldsToUpdate = {
						...fieldsToUpdate,
						nbResolvedConvs: currentState.nbResolvedConvs + 1
					};
				}
			} else {
				//Just replace the conversation in the list
				newConversations = newConversations.map(conv =>
					conv.header.fID === conversation.header.fID ? conversation : conv
				);
			}

			return {
				...currentState,
				...fieldsToUpdate,
				conversations: newConversations,
				selectedConversation:
					!lod_.isNil(currentState.selectedConversation) &&
					currentState.selectedConversation.header.fID === conversation.header.fID
						? conversation
						: currentState.selectedConversation
				/*	conversations: currentState.conversations.filter(
					conv => conv.header.fID !== conversation.header.fID
				)*/
			};
		}

		case C.UPDATE_CONV_MARKETPLACE: {
			const fID = action.payload.fID;
			const marketplace = action.payload.marketplace;
			let updatedConversation = { ...currentState.selectedConversation };

			/**
			 * TODO :
			 *
			 * Je pense ne pas avoir compris ce code mais je ne vois pas le lien entre fID et la selectedConversation
			 * J'ai bien peur que l'on change le state de la conv actuelle et non de celle de la fID
			 */
			if (lod_.isNil(updatedConversation?.header?.extra)) {
				return {
					...currentState
				};
			}

			updatedConversation.header.extra.sourceMarketplace = marketplace;
			updatedConversation.meta.cockpit.site = marketplace;
			if (lod_.has(updatedConversation, "context.order.marketplace")) {
				updatedConversation.context.order.marketplace.marketplaceName = marketplace;
			}

			return {
				...currentState,
				conversations: currentState.conversations.map(conv =>
					conv.header.fID === fID ? updatedConversation : conv
				),
				selectedConversation:
					currentState.selectedConversation?.header?.fID === fID
						? updatedConversation
						: currentState.selectedConversation
			};
		}

		case C.UPDATE_CONVERSATION_CONTEXT: {
			const data = action.payload;
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						pointdelivraison: {
							waterMeter: {
								watermeterID: data.pointdelivraison.waterMeter.watermeterID,
								watermeterPicture: data.pointdelivraison.waterMeter.watermeterPicture
							}
						}
					}
				}
			};
		}

		case C.UPDATE_CONV_CONTEXT_AND_CONTACT: {
			const data = action.payload;
			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: data.payload.context,
					contact: data.payload.contact
				}
			};
		}

		case C.SET_IS_COCKPIT_ARCHIVED: {
			return { ...currentState, isCockpitArchived: action.payload };
		}

		case C.UPDATE_AMAZON_DISPUTE: {
			const data = action.payload;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: { ...currentState.selectedConversation.context, dispute: data }
				}
			};
		}

		case C.UPDATE_AMAZON_REQUEST_PROOF: {
			const data = action.payload;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof: data
					}
				}
			};
		}

		case C.UPDATE_AMAZON_REQUEST_PROOF_ISSENT: {
			const data = action.payload;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof: {
							...currentState.selectedConversation.context.proof,
							isSent: data
						}
					}
				}
			};
		}

		case C.SELECT_AMAZON_REQUEST_PROOF_CONVERSATION: {
			const data = action.payload;

			return {
				...currentState,
				requestProofConversation: data
			};
		}

		case C.AMAZON_PROOF_REQUEST_SENT: {
			return {
				...currentState,
				dispute: {
					...currentState.dispute,
					proofRequestisSent: true
				}
			};
		}

		case C.ADD_AMAZON_FID_TO_CASE: {
			const data = action.payload;

			return {
				...currentState,
				selectedConversation: {
					...currentState.selectedConversation,
					context: {
						...currentState.selectedConversation.context,
						proof: {
							...currentState.selectedConversation.context.proof,
							isSent: data
						},
						conversation: {
							...currentState.selectedConversation.context.conversation,
							relatedfID: data.relatedfID
						}
					}
				}
			};
		}
		case C.SET_SUBJECT: {
			return {
				...currentState,
				subject: action.payload
			};
		}
		case C.SET_CONVS_RELOAD: {
			return {
				...currentState,
				convsReload: action.payload
			};
		}
		case C.SET_ATTACHMENT_DRAFT: {
			return {
				...currentState,
				attachments: action.payload
			};
		}

		case C.UPDATE_CONV_LIST_DISPLAY_STATUS: {
			return {
				...currentState,
				isConvListMinimized: !currentState.isConvListMinimized
			};
		}

		default:
			return currentState;
	}
	return {
		...currentState
	};
};

export default cockpitReducers;

function updateTotalNbForTheRightTab(header, currentState, remove, targetState) {
	if (!targetState) {
		targetState = header.state;
	}
	if (isConversationLive(header)) {
		return {
			...currentState,
			nbLiveConvs: remove ? currentState.nbLiveConvs - 1 : currentState.nbLiveConvs + 1
		};
	}
	//if conversation state = "waiting"
	else if (targetState === "waiting") {
		return {
			...currentState,
			nbWaitingConvs: remove ? currentState.nbWaitingConvs - 1 : currentState.nbWaitingConvs + 1
		};
	}
	//if conversation state = "ongoing"
	else if (targetState === "ongoing") {
		return {
			...currentState,
			nbOngoingConvs: remove ? currentState.nbOngoingConvs - 1 : currentState.nbOngoingConvs + 1
		};
	}
	//if conversation state = "resolved"
	else if (targetState === "resolved") {
		return {
			...currentState,
			nbResolvedConvs: remove ? currentState.nbResolvedConvs - 1 : currentState.nbResolvedConvs + 1
		};
	}
	//if conversation state = "hidden"
	else if (targetState === "hidden") {
		return {
			...currentState,
			nbWaitingConvs: currentState.nbWaitingConvs - 1,
			nbCurrentConvs: [currentState.nbCurrentConvs[0], currentState.nbCurrentConvs[1] - 1]
		};
	}
}
