import React, { useEffect, useState } from "react";
import i18n from "i18next";

import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
	Typography
} from "@material-ui/core";
import lod_ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import { replaceInTextHbr } from "helpers/utilities";

const ScrappingDataInput = ({ anchorEl, handleClose, handleSubmit, actionObject }) => {
	const [searchCompanyURL, setSearchCompanyURL] = useState("");
	const [searchContactURL, setSearchContactURL] = useState("");
	const [searchContact, setSearchContact] = useState("");
	const [showTooltipCompany, setShowTooltipCompany] = useState(false);
	const [showTooltipContact, setShowTooltipContact] = useState(false);
	const [coockieSession, setCoockieSession] = useState("");
	const [userAgent, setUserAgent] = useState("");
	const { selectedConversation } = useSelector(state => state.cockpit);

	const dispatch = useDispatch();

	useEffect(() => {
		handleGetCoockie(actionObject?.config?.actionExt);

		if (
			!lod_.isNil(actionObject?.config?.mapping?.in) &&
			!lod_.isEmpty(actionObject?.config?.mapping?.in)
		) {
			let searchMapping = replaceInTextHbr(actionObject.config.mapping.in, selectedConversation);
			if (!lod_.isNil(searchMapping) && !lod_.isEmpty(searchMapping)) {
				setSearchContact(searchMapping);
			}
		}
	}, []);

	function handleValidate() {
		const regex = actionObject?.config?.regex;
		let regexString = new RegExp(regex);
		let testURL = true;

		if (lod_.isNil(actionObject?.config?.regex) || lod_.isEmpty(actionObject?.config?.regex)) {
			return;
		}
		if (!lod_.isEmpty(searchCompanyURL) && !regexString.test(searchCompanyURL)) {
			setShowTooltipCompany(true);
			testURL = false;
		} else {
			setShowTooltipCompany(false);
		}
		if (!lod_.isEmpty(searchContactURL) && !regexString.test(searchContactURL)) {
			setShowTooltipContact(true);
			testURL = false;
		} else {
			setShowTooltipContact(false);
		}

		if (testURL === true) {
			handleSubmit(
				{
					urlCompany: searchCompanyURL,
					urlContact: searchContactURL,
					searchContact: searchContact,
					coockie: coockieSession,
					userAgent
				},
				actionObject.action
			);
		}
	}

	function handleGetCoockie(action) {
		if (!lod_.isNil(action)) {
			const message = { action };

			const event = new CustomEvent("toFaibrikExtension", { detail: message });
			document.dispatchEvent(event);
		} else {
			dispatch(infoMsg(i18n.t("COC.ACTIONS.scrappingActionMissing")));
		}
	}

	document.addEventListener("fromFaibrikExtension", event => {
		const data = event.detail;
		if (lod_.isNil(data?.cookie) && !lod_.isNil(actionObject?.config?.urlExt)) {
			window.open(actionObject.config.urlExt, "_blank");
		} else if (!lod_.isNil(data?.cookie) && !lod_.isEmpty(data?.cookie)) {
			setCoockieSession(data.cookie);
			setUserAgent(navigator.userAgent);
		} else {
			dispatch(infoMsg(i18n.t("COC.ACTIONS.scrappingNoCoockie")));
		}
	});

	return (
		<Dialog
			fullWidth={true}
			onClose={handleClose}
			maxWidth={"sm"}
			open={open}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{i18n.t("COC.ACTIONS.scrappingAction")}</DialogTitle>
			<DialogContent dividers>
				<Box display="flex" flexDirection="row" alignItems="center" padding="10px">
					{lod_.isEmpty(coockieSession) ? (
						<Box
							display="flex"
							style={{ flexDirection: "column", width: "100%", alignItems: "center" }}
						>
							<Typography>{i18n.t("COC.ACTIONS.scrappingActionRefresh")}</Typography>

							<Button
								color="primary"
								simple
								onClick={() => handleGetCoockie(actionObject?.config?.actionExt)}
								variant="contained"
							>
								{i18n.t("COC.ACTIONS.scrappingRefresh")}
							</Button>
						</Box>
					) : (
						<Box width="100%">
							<Box>
								<TextField
									variant="outlined"
									type="text"
									fullWidth
									value={searchContact}
									label={i18n.t("COC.ACTIONS.scrappingSearchContact")}
									onChange={event => setSearchContact(event.target.value)}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</Box>
							<Box style={{ paddingTop: "2%" }}>
								<TextField
									variant="outlined"
									type="text"
									fullWidth
									value={searchContactURL}
									error={showTooltipContact}
									helperText={showTooltipContact === true ? i18n.t("COC.urlValid") : ""}
									label={"URL Contact"}
									onChange={event => setSearchContactURL(event.target.value)}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</Box>
							<Box style={{ paddingTop: "2%" }}>
								<TextField
									variant="outlined"
									type="text"
									fullWidth
									value={searchCompanyURL}
									error={showTooltipCompany}
									helperText={showTooltipCompany === true ? i18n.t("COC.urlValid") : ""}
									label={"URL Entreprise"}
									onChange={event => setSearchCompanyURL(event.target.value)}
									InputLabelProps={{
										shrink: true
									}}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
					{i18n.t("CD.cancel")}
				</Button>
				<Button
					onClick={() => handleValidate()}
					style={{ margin: "2%" }}
					color="primary"
					disabled={
						(lod_.isEmpty(searchCompanyURL) &&
							lod_.isEmpty(searchContact) &&
							lod_.isEmpty(searchContactURL)) ||
						lod_.isEmpty(coockieSession)
					}
				>
					{i18n.t("CD.validate")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ScrappingDataInput;
